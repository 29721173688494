<template>
  <v-app id="inspire">
    <AppBar />
    <v-main fluid>
      <router-view></router-view>
      <v-footer padless app bottom fixed color="#EEE">
        <v-col class="text-left cols=12">
          <small>@2024</small>
        </v-col>
      </v-footer>
    </v-main>    
  </v-app>
</template>

<script>
  import AppBar from '@/components/AppBar.vue';
  export default {
    name: 'Dashboard',
    components: {AppBar},
    data: () => {
      return {}
    }
  }
</script>
<style scoped>
  small {
    font-weight: bold;
  }
    #inspire {
        background-image: url('../assets/cover.png');
        background-size: cover;
        background-position: center;
        /* background-repeat: no-repeat; */
    }
</style>

import axios from "axios";
import { generateRoutes } from './routeGenerator';
import VueRouter from "vue-router";
// import store from "@/store";

export const updateDynamicRoutes = async (router) => {
    try {
        // const module = store.state.mods;
        const response = await axios.get(`http://165.22.55.202:8000/api/vueroute/`);
        // const response = await axios.get(`http://127.0.0.1:8000/api/vueroute/`);
        const routeData = response.data
        const routesArray = Array.isArray(routeData) ? routeData : [routeData];

        const dynamicRoutes = generateRoutes(routesArray);

        if (dynamicRoutes.some(route => !route.path)) {
            console.error('Some dynamic route are missing "path" property.')
        } else {
            const newRouter = new VueRouter({
                mode: 'history',
                base: process.env.BASE_URL,
                routes: [
                    // {
                    //     path: '/',
                    //     name: 'home',
                    //     component: () => import('@/views/HomeView.vue'),
                    // },                    
                    {
                        path: '/login',
                        name: 'login',
                        component: () => import('@/views/Login.vue'),
                    }, 
                    {
                        path: '/unauthorized',
                        name: 'unauthorized',
                        component: () => import('@/views/Unauthorized.vue'),
                    },
                ],
            })
            router.matcher = newRouter.matcher;
            dynamicRoutes.forEach(route => {
                router.addRoute(route)
            });
            console.log(router)
        }
    } catch (err) {
        console.error('Error fetching dynamic routes:', err)
    }
}
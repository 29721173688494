export const generateRoutes = (routeData) => {
    return routeData.map(routeInfo => {
        const route = {
            path: routeInfo.path,
            name: routeInfo.name,
            component: () => import(`@/views/${routeInfo.component}.vue`),
            meta: { requiresLogin: routeInfo.login },
        };

        if (routeInfo.children && Array.isArray(routeInfo.children)) {
            route.children = generateRoutes(routeInfo.children)
        }

        return route;

    })
}
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access: null,
    refresh: null,
    username: null,
    userId: null,
    role: null,
    office: null,
    isAuthenticated: false,
    agency: 'PROVINCE OF SARANGANI',
    address: 'Alabel, Sarangani Province',
    mods: 'ADMIN',
  },
  getters: {
    loggedIn (state) {
      return state.access != null
    },
    hasMods (state) {
      return state.mods != null
    }
  },
  mutations: {
    initializeStore (state) {
      if (localStorage.getItem('refresh')) {
        this.dispatch('pageRefresh', {
          refresh: localStorage.getItem('refresh'),
          access: localStorage.getItem('access')
        })
      } else {
        this.state.access = null
        this.state.refresh = null
        this.state.username = null
        this.state.userId = null
        this.state.role = null
        this.state.office = null
        this.state.isAuthenticated = false
      }
    },
    updateStorage(state, { access, refresh }) {
      const decode = jwtDecode(access)
      state.access = access
      state.refresh = refresh
      state.username = decode.username
      state.userId = decode.userId
      state.role = decode.role
      state.office = decode.office
      localStorage.setItem('access', access)
      localStorage.setItem('refresh', refresh)
      axios.defaults.headers.common['Authorization'] = "Bearer " + access
      state.isAuthenticated = true
    },
    destroyToken(state) {
      state.access = null
      state.refresh = null
      state.username = null
      state.userId = null
      state.role = null
      state.office = null
      state.agency = null
      state.address = null
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      state.isAuthenticated = false
      axios.defaults.headers.common['Authorization'] = ''
    },
    setModule(state, module) {
      state.mods = module
    }
  },
  actions: {
    userLogout(context) {
      if (context.getters.loggedIn) {
        context.commit('destroyToken')
      }
    },
    userLogin(context, userCredentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/token/', {
            username: userCredentials.username,
            password: userCredentials.password
          })
          .then(response => {
            context.commit('updateStorage', {
              access: response.data.access,
              refresh: response.data.refresh
            })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    pageRefresh(context, token) {
      return new Promise((resolve, reject) => {
        const unintereptedAxiosInstance = axios.create();
        unintereptedAxiosInstance.defaults.headers.common['Authorization'] = "Bearer " + token.access
        unintereptedAxiosInstance
          .post('/token/refresh/', {
            refresh: token.refresh
          })
          .then(response => {
            context.commit('updateStorage', {
              access: response.data.access,
              refresh: response.data.refresh
            })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    inspectToken() {
      if(this.state.isAuthenticated) {
        const decode = jwtDecode(localStorage.getItem('access'))
        const exp = decode.exp
        const iat = decode.iat
        if(((Date.now()/1000) - iat < 628200) && (exp - (Date.now()/1000) < 1800)) {
          this.dispatch('pageRefresh', {
            refresh: localStorage.getItem('refresh'),
            access: localStorage.getItem('access')
          })
          // return true
        } else if((Date.now()/1000) - iat < 630000) {
          // Do Nothing
          return true
        } else {
          this.dispatch('userLogout')
          this.$router.push({name: 'dashboard'})
          return false
        }
      }
    }
  },
  modules: {
  }
})

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
  beforeCreate() {
    // this.$store.commit('initializeStore')
  }
}
</script>

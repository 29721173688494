import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import jwtDecode from 'vue-jwt-decode'
import axios from 'axios'
import Print from 'vue-print-nb'
import CKEditor from 'ckeditor4-vue'

Vue.config.productionTip = false
Vue.prototype.$http = axios
axios.defaults.baseURL = 'http://165.22.55.202:8000/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

axios.interceptors.request.use(
  function (config) {
    if (store.dispatch('inspectToken')) {
      return config
    } else {
      store.dispatch('userLogout')
      this.$router.push({name: 'login'})
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

router.beforeEach((to, from, next) => {
  // if (!store.getters.hasMods) {
  //   next({name:'home'}).catch(() => {})
  // }
  const requiresLogin = to.matched.some(record => record.meta.requiresLogin)
  const userAuthorized = true; // check if user is authorized
  if (requiresLogin) {
    if (!store.getters.loggedIn) {
      next({name: 'login'})
    } else if (!userAuthorized) {
      next({name: 'unauthorized'});
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.use(CKEditor)

new Vue({
  router,
  store,
  axios,
  vuetify,
  jwtDecode,
  Print,
  render: h => h(App)
}).$mount('#app')

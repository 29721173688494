<template>
    <v-app-bar elevation="5" app dark color="success">
        <!-- <v-app-bar-nav-icon @click="$emit('toggle-drawer')"></v-app-bar-nav-icon> -->
        <v-app-bar-title style="font-size: 13pt; max-width: unset !important;">KATARUNGANG PAMBARANGAY RECORD SYSTEM</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y open-on-hover>
            <template v-slot:activator="{on, attrs}">
                <v-btn text v-bind="attrs" v-on="on">
                    Menu
                    <v-icon right>mdi-dots-vertical</v-icon> 
                </v-btn>
            </template>
            <v-list dense color="#cdf7d9">
                <v-list-item to="/dashboard">
                    <v-list-item-title>
                        <v-icon left>mdi-home-outline</v-icon> Home
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/complains">
                    <v-list-item-title>
                        <v-icon left>mdi-folder-account-outline</v-icon> Complaints
                    </v-list-item-title>
                </v-list-item>
                <v-list-item to="/create">
                    <v-list-item-title>
                        <v-icon right>mdi-plus-outline</v-icon> New Complaint
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/lupon">
                    <v-list-item-title>
                        <v-icon left>mdi-account-group-outline</v-icon> Lupon
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                
                <v-list-group @click.stop>
                    <template v-slot:activator>
                        <v-list-item-title>
                            <v-icon left>mdi-file-chart-outline</v-icon> Reports
                        </v-list-item-title>
                    </template>

                    <v-list-item to="/reports/monthlytransmittalform">
                        <v-list-item-title>Monthly Transmittal Report</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/dashboard">
                        <v-list-item-title>Report 2</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-divider></v-divider>
                <v-list-item @click="logout" v-if="$store.state.username != null">
                    <v-list-item-title>
                        <v-icon left>mdi-logout-variant</v-icon>&nbsp; Log-Out
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-else to="/login" link>
                    <v-list-item-title>
                        <v-icon>mdi-login-variant</v-icon>&nbsp; Log-In
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'AppBar',
        computed: {},
        methods: {
            logout() {
                this.$store.dispatch('userLogout')
                this.$router.push({name:'login'}).catch(() => {})
            },
            createComplain() {
                this.$router.push({name:'createComplain'}).catch(() => {})
            }
        }
    }
</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import createVuetify from 'vuetify'

Vue.use(Vuetify);

// const myCustomLightTheme = {
//     dark: false,
//     colors: {
//         background: '#FFFFFF',
//         surface: '#FFFFFF',
//         primary: '#6200EE',
//         'primary-darken-1': '#3700B3',
//         secondary: '#03DAC6',
//         'secondary-darken-1': '#018786',
//         error: '#B00020',
//         info: '#2196F3',
//         success: '#4CAF50',
//         warning: '#FB8C00',    }
// }

export default new Vuetify({
    // theme: {
    //     defaultTheme: 'myCustomLightTheme',
    //     themes: {
    //         myCustomLightTheme,
    //     }
    // }
});
